import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, Rocket, Code, Briefcase, Mail, Github, Linkedin, Menu, X, Phone, MapPin} from 'lucide-react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGithub, AiOutlineLinkedin, AiOutlineWhatsApp, AiOutlineDownload} from 'react-icons/ai';
import { useSwipeable } from 'react-swipeable';

const projects = [
  {
    title: "E-commerce Website - PhoneSale",
    description: "Developed a comprehensive e-commerce platform as part of a collaborative team, utilizing ReactJS for the frontend and Node.js for the backend. Integrated multiple APIs for secure payment processing and user authentication, working closely with security specialists to ensure robust protection. Conducted thorough code reviews to optimize performance and implemented effective SEO strategies, significantly enhancing site visibility. Collaborated on building an intuitive admin panel for managing products, orders, and user accounts, ensuring usability based on user feedback. Innovated an AI feature that provides users with real-time mobile phone price comparisons, strengthening user trust and optimizing pricing strategies.",
    link: "https://phonesale.org",
    year: 2024
  },
  {
    title: "Trade Team Dashboard App - SysTecx",
    description: "Contributed to the development of a trade team dashboard mobile app for SysTecx using React Native and Expo, enabling real-time data access and improved team collaboration. Integrated and manipulated data from various APIs to provide a cohesive and user-friendly experience, enhancing functionality and increasing app reliability. Led debugging and testing phases to ensure compatibility across multiple devices, which resulted in a smooth and successful deployment. Coordinated with team members to optimize performance and respond to user feedback, further refining the app's interface and usability.",
    link: "https://drive.google.com/file/d/1U1xRAkigOfC3Dih2auwUFUpWuPyZpBti/view?usp=sharing", 
    year: 2023
  },
  {
    title: "Responsive Portfolio - Gladyreigh",
    description: "Crafted a modern, responsive portfolio website using React and Tailwind CSS, incorporating constructive feedback from peers and mentors to refine design and functionality. Partnered with a designer to implement smooth animations and a mobile-friendly layout, effectively showcasing projects and skills.",
    link: "https://greyhoven.pages.dev",
    year: 2023
  }
];

const skills = [
  "HTML", "CSS (TailwindCSS)", "JavaScript", "ReactJS", "React Native", "NextJS", "PHP", "Node.js",
  "MySQL", "MongoDB", "FireBase", "SQL", "Azure", "GitHub Actions", "SEO", "API Integration", "TypeScript",
  "UI/UX Design", "AWS", "Firebase", "Java", "Python", "C++", "C#", "VB"
];

const tools = [
  "Visual Studio Code", "Git", "GitHub", "MS Azure", "Adobe Creative Suite (familiarity)",
  "Browser Developer Tools", "Figma"
];

const education = [
  {
    title: "B.Sc. in Computer Science (Distance Learning)",
    institution: "University of London, Goldsmiths, UK",
    duration: "",
    location: "Dubai, UAE"
  }
];

const certifications = [
  {
    title: "Harvard CS50 - Fundamentals of Computer Science",
    institution: "Harvard University",
    date: "Oct 2024",
    credentialId: "16b1fd58-eef3-403d-aaca-d4d3c27d26f2",
    url: "https://cs50.harvard.edu/certificates/16b1fd58-eef3-403d-aaca-d4d3c27d26f2"
  },
  {
    title: "Full Stack Open",
    institution: "University of Helsinki",
    date: "Aug 2024",
    credentialId: "452e8eadc5c265d37b8ce3d7e740d21e",
    url: "https://studies.cs.helsinki.fi/stats/api/certificate/fullstackopen/en/452e8eadc5c265d37b8ce3d7e740d21e"
  },
  {
    title: "GraphQL - Front-End Development - React - Full Stack Web Development",
    institution: "University of Helsinki",
    date: "Aug 2024",
    credentialId: "14bd4ed0582dfa0b4cafd9e7f3f79c05",
    url: "https://studies.cs.helsinki.fi/stats/api/certificate/fs-graphql/en/14bd4ed0582dfa0b4cafd9e7f3f79c05"
  }
];

const courses = [
  {
    title: "Object Oriented Programming (CM2005-01)",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Programming with Data (CM2015-01)",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Agile Software Projects (CM2020-01)",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Databases, Networks and the Web (CM2040-01)",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Discrete Mathematics",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Web Development",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Software Design and Development",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Computer Security",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Algorithms and Data Structures I",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Fundamentals of Computer Science",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "How Computers Work",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Introduction to Programming II",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Introduction to Programming I",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  },
  {
    title: "Computational Mathematics",
    institution: "University of London, Goldsmiths, UK",
    date: ""
  }
];

const experience = [
  {
    title: "Entrepreneur - Mobile Phone Business",
    company: "",
    duration: "2024–Present",
    location: "Dubai, UAE",
    responsibilities: [
      "Spearheaded the launch of an innovative e-commerce mobile phone business, securing necessary permits and forging strategic partnerships with suppliers.",
      "Collaboratively developed a comprehensive business strategy with marketing and operations teams, successfully creating the platform, phonesale.org.",
      "Engineered an AI-driven feature that aggregates real-time mobile phone prices, significantly enhancing user trust and optimizing pricing strategies.",
      "Orchestrated regular team meetings to ensure adherence to project timelines, surpassing sales targets by achieving over 120 cellphone sales in just two months."
    ]
  },
  {
    title: "Full Stack Developer - Strapi, Laravel, and React Project",
    company: "",
    duration: "2023–Present",
    location: "Dubai, UAE",
    responsibilities: [
      "Partnered with a high-performing team to develop robust web applications utilizing React for the frontend, Strapi as a headless CMS, and Laravel for backend functionality, markedly boosting project efficiency.",
      "Designed and implemented scalable APIs using Strapi, ensuring seamless data integration for client applications, significantly improving user experience.",
      "Harnessed Laravel's capabilities to create secure, high-performance web solutions, driving innovative features tailored to complex client requirements.",
      "Facilitated collaborative brainstorming sessions that resulted in creative problem-solving and enhanced workflow efficiencies.",
      "Conducted comprehensive code reviews and rigorous testing protocols to uphold high standards of code quality and foster a culture of continuous improvement."
    ]
  },
  {
    title: "Full Stack Developer - Trade Team Dashboard for SysTecx",
    company: "",
    duration: "2023-Present",
    location: "Dubai, UAE",
    responsibilities: [
      "Contributed to the development and management of a trade team dashboard app for SysTecx using React Native and Expo, enabling efficient data access and decision-making for trade teams.",
      "Collaborated on API integration, manipulating data from multiple sources to provide a unified view and smooth functionality, improving the app's reliability and user experience.",
      "Conducted testing and debugging sessions to ensure seamless performance across various devices, leading to a successful rollout and positive client feedback."
    ]
  },
  {
    title: "Mobile Software and Hardware Repair",
    company: "",
    duration: "2022–Present",
    location: "Dubai, UAE",
    responsibilities: [
      "Collaborated with a team of technical experts to troubleshoot and resolve complex software and hardware issues on mobile devices, ensuring exceptional client experiences.",
      "Worked closely with customer service teams to provide timely updates and effective solutions, enhancing overall user satisfaction."
    ]
  },
  {
    title: "Full Stack Developer",
    company: "",
    duration: "2017–Present",
    location: "Dubai, UAE",
    responsibilities: [
      "Collaborated with cross-functional teams including designers and product managers to build and maintain responsive websites using HTML, CSS (TailwindCSS), and JavaScript frameworks such as ReactJS and NextJS.",
      "Partnered with backend developers and database administrators to optimize web applications and facilitate integration with various CRMs and third-party APIs.",
      "Contributed to team brainstorming sessions, generating innovative solutions that addressed complex technical challenges.",
      "Managed database systems including MySQL and Firebase, and participated in a DevOps team focused on continuous deployment using GitHub Actions and Azure.",
      "Engaged in agile sprints with cross-functional teams, consistently meeting deadlines while maintaining a high level of code quality."
    ]
  }
];

export default function LegendaryPortfolio() {
  const [activeSection, setActiveSection] = useState('home');
  const [starField, setStarField] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasSwipedOnce, setHasSwipedOnce] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isAtTop = () => scrollPosition === 0;
  const isAtBottom = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    return windowHeight + scrollPosition >= documentHeight - 10; // 10px threshold
  };

  useEffect(() => {
    const generateStars = () => {
      const stars = Array.from({ length: 100 }, () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 2 + 1,
        opacity: Math.random() * 0.5 + 0.5,
      }));
      setStarField(stars);
    };

    generateStars();
    window.addEventListener('resize', generateStars);

    return () => window.removeEventListener('resize', generateStars);
  }, []);

  const sections = ['home', 'about', 'experience', 'projects', 'skills', 'education', 'certifications', 'courses', 'contact'];

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const goToNextSection = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex < sections.length - 1) {
      setActiveSection(sections[currentIndex + 1]);
    }
  };

  const goToPreviousSection = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex > 0) {
      setActiveSection(sections[currentIndex - 1]);
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      if (isAtBottom()) {
        goToNextSection();
        setHasSwipedOnce(true);
      }
    },
    onSwipedDown: () => {
      if (isAtTop()) {
        goToPreviousSection();
        setHasSwipedOnce(true);
      }
    },
    preventDefaultTouchmoveEvent: false, // Change this to false
    trackMouse: false,
    trackTouch: true,
    delta: 150,
    touchEventOptions: { passive: true }, // Change this to true
    rotationAngle: 0,
  });

  return (
    <div {...handlers} className="min-h-screen bg-black text-white overflow-y-auto overflow-x-hidden relative touch-pan-y">
      {/* Star field background */}
      <div className="fixed inset-0 z-0">
        {starField.map((star, index) => (
          <div
            key={index}
            className="absolute rounded-full bg-white"
            style={{
              left: star.x,
              top: star.y,
              width: star.size,
              height: star.size,
              opacity: star.opacity,
            }}
          />
        ))}
      </div>

      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-opacity-20 bg-blue-900 backdrop-filter backdrop-blur-lg">
        <div className="container mx-auto py-4 px-4 flex justify-between items-center md:justify-center">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          >
            <Rocket size={32} className="text-yellow-300" />
          </motion.div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
          <ul className="hidden md:flex space-x-6">
            {sections.map((section) => (
              <li key={section}>
                <button
                  onClick={() => setActiveSection(section)}
                  className={`text-lg font-semibold transition-colors ${
                    activeSection === section ? 'text-yellow-300' : 'text-white hover:text-yellow-200'
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed inset-x-0 top-16 bg-blue-900 bg-opacity-30 backdrop-filter backdrop-blur-lg z-40 md:hidden"
          >
            <ul className="py-4">
              {sections.map((section) => (
                <li key={section} className="px-4 py-2">
                  <button
                    onClick={() => {
                      setActiveSection(section);
                      closeMenu();
                    }}
                    className={`text-lg font-semibold transition-colors ${
                      activeSection === section ? 'text-yellow-300' : 'text-white hover:text-yellow-200'
                    }`}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main content */}
      <main className="container mx-auto pt-20 pb-10 px-4 relative z-10">
        <AnimatePresence mode="wait">
          {activeSection === 'home' && (
            <Section key="home">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="text-center"
              >
                <h1 className="text-4xl md:text-5xl lg:text-7xl font-bold mb-6">Gladyreigh Dela Cruz</h1>
                <h2 className="text-2xl md:text-3xl lg:text-4xl text-blue-300 mb-8">Full Stack Web Developer</h2>
                <motion.div
                  animate={{ y: [0, -10, 0] }}
                  transition={{ repeat: Infinity, duration: 2 }}
                >
                  <Rocket
                    size={64}
                    className="mx-auto text-yellow-300 cursor-pointer"
                    onClick={toggleMenu}
                  />
                </motion.div>
                {/* Image with circular border and onClick handler */}
                <div 
                  className="mt-8 w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-full border-1 border-blue-300 flex items-center justify-center overflow-hidden mx-auto cursor-pointer"
                  onClick={toggleMenu}
                >
                  <img
                    src="image/image.png"
                    alt="Gladyreigh Dela Cruz"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              </motion.div>
            </Section>
          )}

          {activeSection === 'about' && (
            <Section key="about">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">About Me</h2>
              <p className="text-lg md:text-xl leading-relaxed mb-6">
                I am Gladyreigh, a Full Stack Web Developer with over five years of experience in the field. My objective is to design and develop dynamic, responsive web applications that advance the possibilities within the digital landscape.
              </p>
              <p className="text-lg md:text-xl leading-relaxed">
                Equipped with a diverse range of programming languages and tools, I specialize in creating immersive digital experiences that engage users and meet client expectations. From frontend design to backend development, I am committed to delivering high-quality web solutions tailored to the unique needs of each project. Join me in advancing the field of web development as we explore its full potential together.
              </p>
            </Section>
          )}

          {activeSection === 'experience' && (
            <Section key="experience">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Professional Experience</h2>
              {experience.map((job, index) => (
                <motion.div
                  key={index}
                  className="mb-8 bg-blue-900 bg-opacity-40 p-6 rounded-lg backdrop-filter backdrop-blur-lg"
                  whileHover={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <h3 className="text-2xl font-semibold mb-2">{job.title}</h3>
                  <p className="text-xl text-blue-300 mb-2">{job.company}</p>
                  <p className="text-lg mb-4">{job.duration} | {job.location}</p>
                  <ul className="list-disc pl-6">
                  {job.responsibilities.map((resp, idx) => (
                      <li key={idx} className="text-lg mb-2">{resp}</li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </Section>
          )}

          {activeSection === 'projects' && (
            <Section key="projects">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Projects</h2>
              <div className="grid md:grid-cols-2 gap-8">
                {projects.map((project, index) => (
                  <motion.div
                    key={index}
                    className="bg-blue-900 bg-opacity-40 p-6 rounded-lg backdrop-filter backdrop-blur-lg"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <h3 className="text-xl md:text-2xl font-semibold mb-3">{project.title}</h3>
                    <p className="mb-4">{project.description}</p>
                    <a href={project.link} className="text-blue-300 hover:text-blue-200 font-bold">
                      View Project
                    </a>
                  </motion.div>
                ))}
              </div>
            </Section>
          )}

          {activeSection === 'skills' && (
            <Section key="skills">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Skills & Tools</h2>
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-4">Technical Skills</h3>
                <div className="flex flex-wrap gap-2">
                  {skills.map((skill, index) => (
                    <div key={index} className="bg-blue-900 bg-opacity-40 px-3 py-1 rounded-full text-sm">
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-2xl font-semibold mb-4">Tools & Technologies</h3>
                <div className="flex flex-wrap gap-2">
                  {tools.map((tool, index) => (
                    <div key={index} className="bg-blue-900 bg-opacity-40 px-3 py-1 rounded-full text-sm">
                      {tool}
                    </div>
                  ))}
                </div>
              </div>
            </Section>
          )}

          {activeSection === 'education' && (
            <Section key="education">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Education</h2>
              {education.map((edu, index) => (
                <motion.div
                  key={index}
                  className="mb-6 bg-blue-900 bg-opacity-40 p-6 rounded-lg backdrop-filter backdrop-blur-lg"
                  whileHover={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <h3 className="text-2xl font-semibold mb-2">{edu.title}</h3>
                  <p className="text-xl text-blue-300 mb-2">{edu.institution}</p>
                  <p className="text-lg">{edu.duration} | {edu.location}</p>
                </motion.div>
              ))}
            </Section>
          )}

          {activeSection === 'certifications' && (
            <Section key="certifications">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Certifications</h2>
              {certifications.map((cert, index) => (
                <motion.div
                  key={index}
                  className="mb-6 bg-blue-900 bg-opacity-40 p-6 rounded-lg backdrop-filter backdrop-blur-lg"
                  whileHover={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <h3 className="text-2xl font-semibold mb-2">
                    <a href={cert.url} className="text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">
                      {cert.title}
                    </a>
                  </h3>
                  <p className="text-xl text-blue-300 mb-2">
                    <a href={cert.url} className="hover:underline" target="_blank" rel="noopener noreferrer">
                      {cert.institution}
                    </a>
                  </p>
                  <p className="text-lg mb-2">{cert.date}</p>
                  <p className="text-sm">
                    Credential ID: <a href={cert.url} className="hover:underline" target="_blank" rel="noopener noreferrer">{cert.credentialId}</a>
                  </p>
                </motion.div>
              ))}
            </Section>
          )}

          {activeSection === 'courses' && (
            <Section key="courses">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Relevant Courses</h2>
              {courses.map((course, index) => (
                <motion.div
                  key={index}
                  className="mb-4 bg-blue-900 bg-opacity-40 p-4 rounded-lg backdrop-filter backdrop-blur-lg"
                  whileHover={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <h3 className="text-xl font-semibold mb-1">{course.title}</h3>
                  <p className="text-lg text-blue-300">{course.institution}</p>
                  <p className="text-sm">{course.date}</p>
                </motion.div>
              ))}
            </Section>
          )}

          {activeSection === 'contact' && (
            <Section key="contact">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">Contact Me</h2>
              <p className="text-lg md:text-xl mb-4">Let's connect and collaborate on an exciting project!</p>
              <div className="flex flex-col space-y-4">
                <a href="/Gladyreigh.pdf" download className="text-blue-300 hover:text-blue-200">
                  <AiOutlineDownload size={24} className="mr-2" />
                  Download CV
                </a>
                <a href="mailto:greygladyreigh@gmail.com" className="flex items-center text-blue-300 hover:text-blue-200">
                  <AiOutlineMail size={24} className="mr-2" />
                  greygladyreigh@gmail.com
                </a>
                <a href="tel:+971566053401" className="flex items-center text-blue-300 hover:text-blue-200">
                  <AiOutlinePhone size={24} className="mr-2" />
                  +971 56 605 3401
                </a>
                <p className="flex items-center text-blue-300">
                  <MapPin size={24} className="mr-2" />
                  Dubai, United Arab Emirates
                </p>
                <div className="flex space-x-4 mt-4">
                  <a href="https://github.com/gladyreigh" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-200">
                    <AiOutlineGithub size={24} />
                  </a>
                  <a href="https://www.linkedin.com/in/gladyreigh" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-200">
                    <AiOutlineLinkedin size={24} />
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=+971566053401&text=Hello,%20I%20would%20like%20to%20work%20with%20you!" target="_blank" rel="noopener noreferrer" className="text-green-300 hover:text-green-200">
                    <AiOutlineWhatsApp size={24} />
                  </a>
                </div>
              </div>
            </Section>
          )}
        </AnimatePresence>
      </main>

      {/* Footer */}
      <footer className="bg-blue-900 bg-opacity-40 py-4 text-center fixed bottom-0 w-full z-20">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Gladyreigh Dela Cruz. All Rights Reserved.</p>
        </div>
      </footer>

      {/* Swipe indicator */}
      {!hasSwipedOnce && (
        <div className="fixed bottom-16 left-1/2 transform -translate-x-1/2 text-white text-center md:hidden">
          <motion.div
            animate={{ y: [0, -10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </motion.div>
          <p className="mt-2">Swipe up to navigate</p>
        </div>
      )}
    </div>
  );
}

const Section = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
    className="py-10"
  >
    {children}
  </motion.div>
);